import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { mq } from "../utils/presets"
import TopImage from "../components/TopImage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/pro-light-svg-icons"

const Wrapper = styled.div`
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
`

const Button = styled.a`
  background: #4a4a4a;
  color: white;
  padding: 0.5rem 0.9rem;
  border: none;
  border-radius: 12px;
  text-transform: uppercase;
  font-size: 1rem;
  text-decoration: none;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  svg {
    margin-right: 0.4rem;
  }
`

const Textwrapper = styled.div`
  padding: 1rem;
  ${mq.tablet} {
    margin-right: 1rem;
    h1 {
      text-align: left;
      border-bottom: none;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const PageTemplate = ({ data }) => {
  const job = data.job
  const featuredImage = job?.featuredImage

  const seo = job?.seo
  const seoTitle = job?.seo?.opengraphTitle || job.title || ``
  const metaDesc = seo.metaDesc || ``
  const seoImage = job?.featuredImage?.node.localFile.childImageSharp.resize

  return (
    <Layout isFrontPage={false}>
      <Seo
        uri={job.uri}
        seoTitle={seoTitle}
        title={job.title}
        description={metaDesc}
        image={seoImage}
        date={job.date}
        schema={job.seo.schema.raw}
        jobposting="true"
        modified={job.modified}
        jobtype={job.jobSpezifikationen.jobtype}
      />
      {!!featuredImage && <TopImage featuredImage={featuredImage} />}
      <Wrapper>
        <Textwrapper>
          <h1>Stellenausschreibung: {job.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: job.content }} />
          <Button href="mailto:info@rsp-architektur.de?subject=Bewerbung">
            <FontAwesomeIcon className="faicon" icon={faEnvelope} /> Jetzt
            Bewerben
          </Button>
        </Textwrapper>
      </Wrapper>
    </Layout>
  )
}
export default PageTemplate

export const query = graphql`
  query ($id: String) {
    job: wpJob(id: { eq: $id }) {
      title
      content
      uri
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "YYYY-MM-DD")
      jobSpezifikationen {
        jobtype
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              resize(width: 1200, height: 627) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
